export const getCenterCoordinates = () => {
  const center: any = {
    lat: 35.7158670,
    lng: 139.6872020
  }
  return center
}
export const getDefaultZoomLevel = () => {
  const zoom: number = 14
  return zoom
}
